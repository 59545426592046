<template>
  <g>
    <svg:style>
      .cls-10, .cls-11, .cls-12, .cls-3, .cls-4, .cls-5, .cls-6, .cls-7, .cls-9 {
        stroke: #aeaeae;
        stroke-miterlimit: 10;
        stroke-width: 0.25px;
      }

      .cls-3 {
        fill: url(#inox-gradient);
      }

      .cls-4 {
        fill: url(#inox-gradient-2);
      }

      .cls-5 {
        fill: url(#inox-gradient-3);
      }

      .cls-6 {
        fill: url(#inox-gradient-4);
      }

      .cls-7 {
        fill: url(#inox-gradient-5);
      }

      .cls-9 {
        fill: url(#inox-gradient-7);
      }

      .cls-10 {
        fill: url(#inox-gradient-8);
      }

      .cls-11 {
        fill: url(#inox-gradient-9);
      }

      .cls-12 {
        fill: url(#inox-gradient-10);
      }
    </svg:style>

    <linearGradient id="handle-gradient"
                    :x1="doorLeftWidth + 11.29"
                    :y1="doorTopHeight1 + 150.41"
                    :x2="doorLeftWidth + 16.95"
                    :y2="doorTopHeight1 + 150.41"
                    gradientUnits="userSpaceOnUse">
      <stop offset="0.01" stop-color="#babbbc"/>
      <stop offset="0.29" stop-color="#989a9e"/>
      <stop offset="0.65" stop-color="#bdbdc1"/>
      <stop offset="0.87" stop-color="#9d9fa2"/>
      <stop offset="1" stop-color="#a6a8aa"/>
      <stop offset="1" stop-color="#c2c5c9"/>
    </linearGradient>

    <linearGradient id="inox-gradient"
                    :x1="doorLeftWidth + 104.51"
                    :y1="doorTopHeight1 + 279.3"
                    :x2="doorLeftWidth + 104.51"
                    :y2="doorTopHeight1 + 22.23"
                    gradientUnits="userSpaceOnUse">
      <stop offset="0.01" stop-color="#babbbc"/>
      <stop offset="0.29" stop-color="#989a9e"/>
      <stop offset="0.65" stop-color="#bdbdc1"/>
      <stop offset="0.87" stop-color="#9d9fa2"/>
      <stop offset="1" stop-color="#a6a8aa"/>
      <stop offset="1" stop-color="#c2c5c9"/>
    </linearGradient>

    <linearGradient id="inox-gradient-2"
                    data-name="inox-gradient"
                    :x1="doorLeftWidth + 85.04"
                    :y1="doorTopHeight1 + 101.16"
                    :x2="doorLeftWidth + 85.04"
                    :y2="doorTopHeight1 + 81.31"
                    xlink:href="#inox-gradient"/>
    <linearGradient id="inox-gradient-3"
                    data-name="inox-gradient"
                    :x1="doorLeftWidth + 85.04"
                    :y1="doorTopHeight1 + 140.85"
                    :x2="doorLeftWidth + 85.04"
                    :y2="doorTopHeight1 + 121"
                    xlink:href="#inox-gradient"/>
    <linearGradient id="inox-gradient-4"
                    data-name="inox-gradient"
                    :x1="doorLeftWidth + 85.04"
                    :y1="doorTopHeight1 + 180.52"
                    :x2="doorLeftWidth + 85.04"
                    :y2="doorTopHeight1 + 160.68"
                    xlink:href="#inox-gradient"/>
    <linearGradient id="inox-gradient-5"
                    data-name="inox-gradient"
                    :x1="doorLeftWidth + 85.04"
                    :y1="doorTopHeight1 + 220.21"
                    :x2="doorLeftWidth + 85.04"
                    :y2="doorTopHeight1 + 200.37"
                    xlink:href="#inox-gradient"/>
    <linearGradient id="inox-gradient-6"
                    data-name="inox-gradient"
                    :x1="doorLeftWidth + 11.33"
                    :y1="doorTopHeight1 + 150.76"
                    :x2="doorLeftWidth + 17"
                    :y2="doorTopHeight1 + 150.76"
                    xlink:href="#inox-gradient"/>
    <linearGradient id="inox-gradient-7"
                    data-name="inox-gradient"
                    :x1="doorLeftWidth + 85.04"
                    :y1="doorTopHeight1 + 94.78"
                    :x2="doorLeftWidth + 85.04"
                    :y2="doorTopHeight1 + 87.7"
                    xlink:href="#inox-gradient"/>
    <linearGradient id="inox-gradient-8"
                    data-name="inox-gradient"
                    :x1="doorLeftWidth + 85.04"
                    :y1="doorTopHeight1 + 134.47"
                    :x2="doorLeftWidth + 85.04"
                    :y2="doorTopHeight1 + 127.38"
                    xlink:href="#inox-gradient"/>
    <linearGradient id="inox-gradient-9"
                    data-name="inox-gradient"
                    :x1="doorLeftWidth + 85.04"
                    :y1="doorTopHeight1 + 174.15"
                    :x2="doorLeftWidth + 85.04"
                    :y2="doorTopHeight1 + 167.06"
                    xlink:href="#inox-gradient"/>
    <linearGradient id="inox-gradient-10"
                    data-name="inox-gradient"
                    :x1="doorLeftWidth + 85.04"
                    :y1="doorTopHeight1 + 213.82"
                    :x2="doorLeftWidth + 85.04"
                    :y2="doorTopHeight1 + 206.74"
                    xlink:href="#inox-gradient"/>

    <filter id="inset-shadow">
      <feOffset dx="0" dy="0"/>
      <feGaussianBlur stdDeviation="1"  result="offset-blur"/>
      <feComposite operator="out" in="SourceGraphic" in2="offset-blur" result="inverse"/>
      <feFlood flood-color="black" flood-opacity="1" result="color"/>
      <feComposite operator="in" in="color" in2="inverse" result="shadow"/>
      <feComponentTransfer in="shadow" result="shadow">
        <feFuncA type="linear" slope="1.25"/>
      </feComponentTransfer>
      <feComposite operator="over" in="shadow" in2="SourceGraphic"/>
    </filter>

    <g id="L14">
      <rect id="inox"
            v-if="showInox"
            data-name="inox"
            class="cls-3"
            :x="inox1X"
            :y="inox1Y"
            width="2.13"
            :height="inoxHeight"/>

      <path id="inox-2"
            v-if="showInox"
            data-name="inox"
            class="cls-4"
            :d="`
              M ${doorLeftWidth1 + 75.12} , ${doorTopHeight1 + 81.31}
              v 19.85
              H ${doorLeftWidth1 + 95}
              V ${doorTopHeight1 + 81.31}
              H ${doorLeftWidth1 + 75.12}
              Z
              M ${doorLeftWidth1 + 92.83} , ${doorTopHeight1 + 99}
              H ${doorLeftWidth1 + 77.24}
              V ${doorTopHeight1 + 83.45}
              h 15.6
              V ${doorTopHeight1 + 99}
              Z
            `"/>

      <path id="inox-3"
            v-if="showInox"
            data-name="inox"
            class="cls-5"
            :d="`
              M ${doorLeftWidth1 + 75.12} , ${doorTopHeight1 + 121}
              v 19.85
              H ${doorLeftWidth1 + 95}
              V ${doorTopHeight1 + 121}
              H ${doorLeftWidth1 + 75.12}
              Z
              m 17.72 , 17.72
              H ${doorLeftWidth1 + 77.24}
              V ${doorTopHeight1 + 123.13}
              h 15.6
              v 15.59
              Z
            `"/>

      <path id="inox-4"
            v-if="showInox"
            data-name="inox"
            class="cls-6"
            :d="`
              M ${doorLeftWidth1 + 75.12} , ${doorTopHeight1 + 160.68}
              v 19.84
              H ${doorLeftWidth1 + 95}
              V ${doorTopHeight1 + 160.68}
              H ${doorLeftWidth1 + 75.12}
              Z
              M ${doorLeftWidth1 + 92.83} , ${doorTopHeight1 + 178.4}
              H ${doorLeftWidth1 + 77.24}
              v -15.6
              h 15.6
              v 15.6
              Z
            `"/>

      <path id="inox-5"
            v-if="showInox"
            data-name="inox"
            class="cls-7"
            :d="`
              M ${doorLeftWidth1 + 75.12} , ${doorTopHeight1 + 200.37}
              v 19.84
              H ${doorLeftWidth1 + 95}
              V ${doorTopHeight1 + 200.37}
              H ${doorLeftWidth1 + 75.12}
              Z
              m 17.72 , 17.71
              H ${doorLeftWidth1 + 77.24}
              V ${doorTopHeight1 + 202.49}
              h 15.6
              v 15.59
              Z
            `"/>

      <polyline id="inox-6"
                v-if="showInox"
                data-name="inox"
                class="cls-9"
                :points="`
                  ${doorLeftWidth1 + 88.58} ${doorTopHeight1 + 87.7}
                  ${doorLeftWidth1 + 81.49} ${doorTopHeight1 + 87.7}
                  ${doorLeftWidth1 + 81.49} ${doorTopHeight1 + 94.78}
                  ${doorLeftWidth1 + 88.58} ${doorTopHeight1 + 94.78}
                  ${doorLeftWidth1 + 88.58} ${doorTopHeight1 + 87.7}
                `"/>
      <polyline id="inox-7"
                v-if="showInox"
                data-name="inox"
                class="cls-10"
                :points="`
                  ${doorLeftWidth1 + 88.58} ${doorTopHeight1 + 127.38}
                  ${doorLeftWidth1 + 81.49} ${doorTopHeight1 + 127.38}
                  ${doorLeftWidth1 + 81.49} ${doorTopHeight1 + 134.47}
                  ${doorLeftWidth1 + 88.58} ${doorTopHeight1 + 134.47}
                  ${doorLeftWidth1 + 88.58} ${doorTopHeight1 + 127.38}
                `"/>
      <polyline id="inox-8"
                v-if="showInox"
                data-name="inox"
                class="cls-11"
                :points="`
                  ${doorLeftWidth1 + 88.58} ${doorTopHeight1 + 167.06}
                  ${doorLeftWidth1 + 81.49} ${doorTopHeight1 + 167.06}
                  ${doorLeftWidth1 + 81.49} ${doorTopHeight1 + 174.15}
                  ${doorLeftWidth1 + 88.58} ${doorTopHeight1 + 174.15}
                  ${doorLeftWidth1 + 88.58} ${doorTopHeight1 + 167.06}
                `"/>
      <polyline id="inox-9"
                v-if="showInox"
                data-name="inox"
                class="cls-12"
                :points="`
                  ${doorLeftWidth1 + 88.58} ${doorTopHeight1 + 206.74}
                  ${doorLeftWidth1 + 81.49} ${doorTopHeight1 + 206.74}
                  ${doorLeftWidth1 + 81.49} ${doorTopHeight1 + 213.82}
                  ${doorLeftWidth1 + 88.58} ${doorTopHeight1 + 213.82}
                  ${doorLeftWidth1 + 88.58} ${doorTopHeight1 + 206.74}
                `"/>
    </g>
  </g>
</template>

<script>
export default {
  props: [
    'doorWidth',
    'doorHeight',
    'doorLeftWidth',
    'doorTopHeight',
    'doorGlassImage',
    'showInox',
    'showBg',
  ],
  data() {
    return {
      inoxOffset: 22.26,
      leaf2Left: 7,
    }
  },
  computed: {
    inoxHeight() {
      return this.showBg ?
        this.doorHeight - 2 * this.inoxOffset :
        this.doorHeight - 2 * this.leaf2Left
    },
    inox1X() {
      return this.doorLeftWidth + this.doorWidth * .75
    },
    inox1Y() {
      return this.showBg ?
        this.doorTopHeight + this.inoxOffset :
        this.doorTopHeight + this.leaf2Left
    },
    doorLeftWidth1() {
      return this.doorLeftWidth + this.doorWidth/2 - 70
    },
    doorTopHeight1() {
      return this.doorTopHeight + this.doorHeight/2 - 150
    },
  },
}
</script>
